<template>
  <section class="sec--camp">
    <div
      class="cont--bg"
      :style="{ 'background-image': 'url(' + bgImg + ')' }"
    ></div>
    <ul class="cont--imgs">
      <li><img :src="slImg01" alt="" /></li>
      <li><img :src="slImg02" alt="" /></li>
      <li><img :src="slImg03" alt="" /></li>
      <li><img :src="slImg04" alt="" /></li>
      <li><img :src="slImg05" alt="" /></li>
      <li>
        <img
          :src="slImg06"
          alt=""
          usemap="#image-maps-summer-camp"
          id="map-summer-camp"
        />
        <map name="image-maps-summer-camp" id="map-summer-camp">
          <area
            alt=""
            title=""
            href="mailto:info@thegolfmecca.com"
            shape="rect"
            coords="69,81,553,143"
            :style="{ outline: 'none' }"
            target="_blank"
          />
          <area
            alt=""
            title=""
            href="https://www.facebook.com/GolfMeccaVancouver"
            shape="rect"
            coords="609,74,1093,145"
            :style="{ outline: 'none' }"
            target="_blank"
          />
          <area
            alt=""
            title=""
            href="tel:+17789570722"
            shape="rect"
            coords="74,155,437,226"
            :style="{ outline: 'none' }"
            target="_blank"
          />
          <area
            alt=""
            title=""
            href="https://www.instagram.com/golf_mecca_center"
            shape="rect"
            coords="611,162,1029,233"
            :style="{ outline: 'none' }"
            target="_blank"
          />
          <area
            shape="rect"
            coords="1198,306,1200,308"
            alt="Image Map"
            :style="{ outline: 'none' }"
            title="Image Map"
          />
        </map>
      </li>
    </ul>
    <a @click="$router.go(-1)" class="btn--back"> Go back </a>
  </section>
</template>

<script>
import bgImg from "@/assets/gallery/bg_gallery.jpg";
import slImg01 from "@/assets/camp/golfmecca_poster_camp_summer_en_01.jpg";
import slImg02 from "@/assets/camp/golfmecca_poster_camp_summer_en_02.jpg";
import slImg03 from "@/assets/camp/golfmecca_poster_camp_summer_en_03.jpg";
import slImg04 from "@/assets/camp/golfmecca_poster_camp_summer_en_04.jpg";
import slImg05 from "@/assets/camp/golfmecca_poster_camp_summer_en_05.jpg";
import slImg06 from "@/assets/camp/golfmecca_poster_camp_summer_en_06.jpg";

export default {
  name: "Camp",
  components: {},
  data: () => {
    return {
      bgImg: bgImg,
      slImg01: slImg01,
      slImg02: slImg02,
      slImg03: slImg03,
      slImg04: slImg04,
      slImg05: slImg05,
      slImg06: slImg06,
      sectionTxt: {
        title: `Summer Camp 2022`
      }
    };
  },
  methods: {},
  mounted() {}
};
</script>
